import { ReactNode } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

function SearchResultsCount({ count }: Readonly<{ count: number }>): ReactNode {
  const { t } = useTranslation();

  return <Text>{count} {count > 1 ? t('DISPLAY_RESULTS_NBR') : t('DISPLAY_RESULTS_NBR_SINGULAR')}</Text>;
}

export default SearchResultsCount;
