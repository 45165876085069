import { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Avatar, Dropdown, Menu, Space } from 'antd';
import { DownOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import type { MenuProps } from 'antd';
import { useScreenSize, useChangeLanguage, mapI18N } from '@shopopop/react-hooks';
import { countryAlphaOrder } from '@shopopop/backoffice-frontend-utils';

interface MyAccountProps {
  onLogout: () => void;
  transformPosition?: {x: number, y: number};
}

/**
 * The MyAccount component.
 * @return {ReactNode} The MyAccount component.
 */
function MyAccount({ onLogout, transformPosition }: MyAccountProps): ReactNode {
  const { t } = useTranslation();
  const { mobileMode } = useScreenSize();
  const languages = countryAlphaOrder(mapI18N).map((lang) => {
    return ({
      value: lang.name,
      label: lang.name,
    });
  });

  const handleChangeLanguage = useChangeLanguage();

  type MenuItem = Required<MenuProps>['items'][number];

  const items: MenuItem[] = [
    {
      key: 'languages',
      label: t('LANGUAGE_CHOICE'),
      type: 'group',
      children: languages.map((lang) => ({
        key: lang.value,
        label: lang.label,
        onClick: () => handleChangeLanguage(lang.value),
      })),
    },
    {
      type: 'divider',
    },
    {
      key: 'logout',
      label: <div onClick={onLogout}>{t('LOG_OUT')}</div>,
      icon: <LogoutOutlined />,
    },
  ];

  return (
    <Dropdown
      dropdownRender={() => (
        <StyledMyAccount
          items={items}
          mode="inline"
          transformPosition={transformPosition}
          onLogout={onLogout}
        />
      )}
    >
      <Space>
        <Avatar size="small" icon={<UserOutlined />} />
        {!mobileMode && t('MY_ACCOUNT_SECTION')}
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}

const StyledMyAccount = styled(Menu)`
  transform: translate(
    ${(props: MyAccountProps) => props.transformPosition?.x || 0}px, 
    ${(props: MyAccountProps) => props.transformPosition?.y || 0}px
  );
`;

export default MyAccount;
