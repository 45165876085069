import { ReactNode } from 'react';
import { Table, TablePaginationConfig, TableProps, theme } from 'antd';
import { useMarkFirstOfProperty } from '@shopopop/react-hooks';
import dayjs from 'dayjs';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { DEFAULT_PAGINATION } from '@shopopop/backoffice-frontend-utils';

interface EnhancedTableProps<T> extends TableProps<T> {
  data: T[];
  pagination: TablePaginationConfig;
}

interface TableRowProps<T> {
  children: ReactNode;
  props: any;
  data: T[];
}

interface TableRowData {
  key: string;
  isFirstOfDay?: boolean;
  date?: string;
}

function EnhancedTableOnDate<T extends TableRowData>({
  data,
  pagination,
  ...tableProps
}: EnhancedTableProps<T>): ReactNode {
  const modifiedData = useMarkFirstOfProperty<T>({
    data,
    currentPage: pagination?.current || DEFAULT_PAGINATION.current,
    pageSize: pagination?.pageSize || DEFAULT_PAGINATION.pageSize,
    getProperty: (entry) => dayjs(entry.date).format('YYYY-MM-DD'),
  });
  const { token: { colorBgLayout, colorTextSecondary }} = theme.useToken();

  const PropertyMarkedTableRow = ({ children, props, data }: TableRowProps<T>) => {
    const record = props['data-row-key'] !== undefined ? data.find((d) => d.key === props['data-row-key']) : undefined;
    const property = record ? record.date : undefined;

    return (
      <StyledRow
        {...props}
        isFirstOfDay={record?.isFirstOfDay}
        date={property ? String(property) : undefined}
        rowInfoContent={property ? String(property) : undefined}
        colorBgLayout={colorBgLayout ? String(colorBgLayout) : undefined}
        colorTextSecondary={colorTextSecondary ? String(colorTextSecondary) : undefined}
      >
        {children}
      </StyledRow>
    );
  };

  return (
    <Table
      {...tableProps}
      dataSource={modifiedData}
      pagination={{
        ...pagination,
        showSizeChanger: true,
      }}
      loading={tableProps.loading}
      components={{
        body: {
          row: (props: any) => (
            <PropertyMarkedTableRow props={props} data={modifiedData}>
              {props.children}
            </PropertyMarkedTableRow>
          ),
        },
      }}
    />
  );
}

const firstOfDayStyles = (date?: string, colorBgLayout?: string, colorTextSecondary?: string) => css`
    position: relative;

    > td {
        padding: calc(16px + 32px) 16px 16px 16px !important;
    }

    &::after {
        content: "${date ? dayjs(date).format('DD MMMM YYYY') : ''}";
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        font-weight: 700;
        background-color: ${colorBgLayout};
        padding: 5px 16px;
        color: ${colorTextSecondary};
    }
`;

const StyledRow = styled.tr<{ isFirstOfDay: boolean; date?: string; colorBgLayout?: string; colorTextSecondary?: string }>`
    ${(props) => props.isFirstOfDay && props.colorBgLayout && props.colorTextSecondary && firstOfDayStyles(props.date, props.colorBgLayout, props.colorTextSecondary)}
`;

export default EnhancedTableOnDate;
