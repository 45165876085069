import { ReactNode } from 'react';
import { Space, Typography } from 'antd';
import { useScreenSize } from '@shopopop/react-hooks';

const { Text } = Typography;

interface DeliveryPickupTupleProps {
  pickupPoint: string;
  zipCode: string;
  city: string;
}

function DeliveryPickupTuple({ pickupPoint, zipCode, city }: DeliveryPickupTupleProps): ReactNode {
  const { mobileMode } = useScreenSize();

  return (
    <Space direction={mobileMode ? 'horizontal' : 'vertical'} size={mobileMode ? 'small' : 0}>
      <Text>{pickupPoint}</Text>
      <Text type="secondary">{zipCode} {city}</Text>
    </Space>
  );
}

export default DeliveryPickupTuple;
