import { Space, Typography } from 'antd';

const { Text } = Typography;

export default function TitleCard({ principalWording, secondaryWording }: Readonly <{principalWording: string, secondaryWording?: string}>) {
  return (
    <Space>
      <Text>{principalWording}</Text>
      <Text type="secondary">{secondaryWording}</Text>
    </Space>
  );
}
