import { Space, Typography } from 'antd';

const { Text } = Typography;

interface ContentVerticalCardProps {
  label: string;
  value: string | React.ReactNode;
}

const ContentVerticalCard = ({ label, value }: Readonly <ContentVerticalCardProps>) => {
  return (
    <Space direction='vertical'>
      <Text type="secondary">{label}</Text>
      <Text>{value}</Text>
    </Space>
  );
};

export default ContentVerticalCard;
