/** @jsxImportSource @emotion/react */
import { useEffect, useState, useContext } from 'react';
import { Col, Row, Tabs } from 'antd';
import UserDeliveriesList from './Recipient/UserDeliveriesList';
import UserDeliveriesListMobile from './Recipient/UserDeliveriesListMobile';
import UserGeneralInformations from './UserGloblaInformation/UserGeneralInformations';
import ShopperAcount from './Shopper/ShopperAcount';
import ShopperDeliveriesList from './Shopper/ShopperDeliveriesList';
import ShopperDeliveriesListMobile from './Shopper/ShopperDeliveriesListMobile';
import { useRouter } from '../../hooks/useRouter';
import { useTranslation } from 'react-i18next';
import TouchableView from '../DeviceDetect/TouchableView';
import UntouchableView from '../DeviceDetect/UntouchableView';
import StatusErrorWrapper from '../Wrapper/StatusErrorWrapper';
import { useNavigate } from 'react-router';
import BreadcrumbContext from '../../context/BreadcrumbContext';
import parseInternalCommentBoV1 from '../../utils/parseInternalCommentBoV1';
import CallsToActions from './CallsToActions/CallsToActions';
import { readUser, readUserDocuments, readUserDeactivationReasons } from '../../services/apiEndPoints/user/readUser';
import ShopperCanceledDeliveriesList from './Shopper/ShopperCanceledDeliveriesList';
import ShopperCanceledDeliveriesListMobile from './Shopper/ShopperCanceledDeliveriesListMobile';
import LabelBadgeCounter from '../LabelBadgeCounter/LabelBadgeCounter';
import TabbedCard from '../TabbedCard/TabbedCard';
import AddressesListCard from '../Addresses/AddressesListCard';
import { readUserAddresses } from '../../services/apiEndPoints/addresses/userAddress/readUserAddresses';

/**
 * User
 * @return {string}
 */
function User() {
  const { t } = useTranslation();
  const router = useRouter();
  const navigate = useNavigate();
  const userId = router.query.id;
  const { setInfoBreadcrumb } = useContext(BreadcrumbContext);

  const [loading, setLoading] = useState(false);
  const [statusError, setStatusError] = useState(202);
  const [activeTab, setActiveTab] = useState(router.query.tab);
  const [deliveriesActiveTab, setDeliveriesActiveTab] = useState('deliveries-tab');
  const [userDeactivationReasons, setUserDeactivationReasons] = useState(null);
  const [disabledBanButton, setDisabledBanButton] = useState(false);
  const [isBanUser, setIsBanUser] = useState(false);
  const [user, setUser] = useState(null);
  const [kycView, setKycView] = useState([]);
  const [kycListDatabase, setKycListDatabase] = useState([]);
  const [deliveriesCount, setDeliveriesCount] = useState(0);
  const [addresses, setAddresses] = useState([]);
  const [addressesLoading, setAddressesLoading] = useState(true);

  const onClickTab = (key) => {
    setActiveTab(key);
    navigate(`/users/${router.query.id}?tab=${key}`);
  };

  useEffect(() => {
    if (!user) getUser();
    if (user) {
      readUserAddresses({ userId: userId }).then((response) =>{
        setAddresses(response.data);
        setAddressesLoading(false);
      });
    }
  }, [user]);

  const getUser = () => {
    setLoading(true);
    readUser({
      userId: router.query.id,
    }).then((response) => {
      if (response.data) {
        setUser(response.data);
        setInfoBreadcrumb(response.data);
        getKyc({ user: response.data });
        getUserDeactivationReasons();
      }
      setStatusError(response.status);
      setLoading(false);
    });
  };

  const getUserDeactivationReasons = () => {
    setLoading(true);
    readUserDeactivationReasons({
      userId: router.query.id,
    }).then((response) => {
      setUserDeactivationReasons(response.data);
      setIsBanUser(response.data ? true : false);
      setLoading(false);
    });
  };

  const getKyc = ({ user }) => {
    const identityBelgium = (nationalIdData) => {
      if (nationalIdData.status === null) {
        return { type: 'national_id', status: 'NOT_SENT' };
      }
      return { type: 'national_id', status: 'VALIDATED' };
    };

    const identityCardStatus = (documentStatusArray) => {
      const statusOnfido = documentStatusArray.find((doc) => doc.type === 'id_card_shopopop');
      const statusMango = documentStatusArray.find((doc) => doc.type === 'id_card_mango');
      return [
        ...documentStatusArray,
        { type: 'identity_card', status: statusOnfido?.status === 'VALIDATED' ? statusMango?.status : statusOnfido?.status },
      ];
    };

    readUserDocuments({ userId: router.query.id })
      .then((response) => {
        let KycBuffer = [];
        const userDocumentStatus = response.data;
        for (const property in userDocumentStatus) {
          if (Object.prototype.hasOwnProperty.call(userDocumentStatus, property)) {
            KycBuffer.push({ type: property, status: response.data[property] });
          }
        }

        const nationalIdData = KycBuffer.find((kyc) => kyc.type === 'national_id');
        if (nationalIdData) {
          const indexNationalId = KycBuffer.findIndex((element) => element.type === 'national_id');
          KycBuffer = [
            ...KycBuffer.slice(0, indexNationalId),
            identityBelgium(nationalIdData),
            ...KycBuffer.slice(indexNationalId, +1),
          ];
        }

        KycBuffer = [
          ...identityCardStatus(KycBuffer),
          {
            type: 'phone_verified',
            status: user.phone_verified === 1 ? 'VALIDATED' : 'UNVALIDATED',
          },
        ];

        setKycListDatabase(KycBuffer);

        KycBuffer = KycBuffer.filter((d)=> !d.type.includes('id_card'));
        setKycView(KycBuffer);
      });
  };

  const switchTab = (tabKey) => {
    setDeliveriesActiveTab(tabKey);
    setDeliveriesCount(0);
  };

  const tabsItems = [
    {
      label: t('USER_SHOPPER'),
      key: 'deliverer',
      id: 'users-tab-pane_shopper',
      children: (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <ShopperAcount kycView={kycView} kycListDatabase={kycListDatabase} />
          </Col>
          <Col span={24}>
            <TouchableView>
              <TabbedCard>
                <Tabs
                  type="card"
                  animated
                  destroyInactiveTabPane
                  onTabClick={(key) => setDeliveriesActiveTab(key)}
                  items={[
                    {
                      key: 'deliveries-tab',
                      label: (
                        <LabelBadgeCounter
                          text={t('DELIVERIES')}
                          count={deliveriesCount}
                          active={deliveriesActiveTab === 'deliveries-tab'}
                        />
                      ),
                      children: (
                        <ShopperDeliveriesListMobile
                          count={deliveriesCount}
                          setCount={setDeliveriesCount}
                          statusError={statusError}
                        />
                      ),
                    },
                    {
                      key: 'cancelation-tab',
                      label: (
                        <LabelBadgeCounter
                          text={t('CANCELATIONS')}
                          count={deliveriesCount}
                          active={deliveriesActiveTab === 'cancelation-tab'}
                        />
                      ),
                      children: (
                        <ShopperCanceledDeliveriesListMobile
                          count={deliveriesCount}
                          setCount={setDeliveriesCount}
                          statusError={statusError}
                        />
                      ),
                    },
                  ]}
                />
              </TabbedCard>
            </TouchableView>
            <UntouchableView>
              <TabbedCard>
                <Tabs
                  type="card"
                  animated
                  destroyInactiveTabPane
                  onTabClick={(key) => switchTab(key)}
                  items={[
                    {
                      key: 'deliveries-tab',
                      label: (
                        <LabelBadgeCounter
                          text={t('DELIVERIES')}
                          count={deliveriesCount}
                          active={deliveriesActiveTab === 'deliveries-tab'}
                        />
                      ),
                      children: (
                        <ShopperDeliveriesList
                          count={deliveriesCount}
                          setCount={setDeliveriesCount}
                        />
                      ),
                    },
                    {
                      key: 'cancelation-tab',
                      label: (
                        <LabelBadgeCounter
                          text={t('CANCELATIONS')}
                          count={deliveriesCount}
                          active={deliveriesActiveTab === 'cancelation-tab'}
                        />
                      ),
                      children: (
                        <ShopperCanceledDeliveriesList
                          count={deliveriesCount}
                          setCount={setDeliveriesCount}
                        />
                      ),
                    },
                  ]}
                />
              </TabbedCard>
            </UntouchableView>
          </Col>
        </Row>
      ),
    },
    {
      label: t('USER_CUSTOMER'),
      key: 'recipient',
      id: 'users-tab-pane-customer',
      children: (
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <TouchableView>
              <UserDeliveriesListMobile statusError={statusError}/>
            </TouchableView>
            <UntouchableView>
              <UserDeliveriesList statusError={statusError}/>
            </UntouchableView>
          </Col>
          <Col span={24}>
            <AddressesListCard
              addresses={addresses}
              setAddresses={setAddresses}
              title='ADDRESSES_CARD_TITLE'
              maxRows={2}
              actions={{
                update: true,
                create: true,
                select: false,
              }}
              userId={userId}
              addressesLoading={addressesLoading}
            />
          </Col>
        </Row>
      ),
    },
  ];

  return (
    <StatusErrorWrapper statusError={statusError}>
      <div className='dual-panel-wrapper'>
        <TouchableView>
          <CallsToActions
            setUserDeactivationReasons={setUserDeactivationReasons}
            userDeactivationReasons={userDeactivationReasons}
            disabledBanButton={disabledBanButton}
            setDisabledBanButton={setDisabledBanButton}
            isBanUser={isBanUser}
            setIsBanUser={setIsBanUser}
            getUserDeactivationReasons={getUserDeactivationReasons}
            internalCommentBOV1={parseInternalCommentBoV1(user?.internal_comment)}
            mobile={true}/>
        </TouchableView>
        <div>
          <UserGeneralInformations
            user={user}
            setUser={setUser}
            userDeactivationReasons={userDeactivationReasons}
            disabledBanButton={disabledBanButton}
            isBanUser={isBanUser}
            kycListDatabase={kycListDatabase}
            loading={loading}
          />
        </div>
        <div css={{ marginTop: 30 }}>
          <Tabs
            items={tabsItems}
            animated
            tabBarExtraContent={
              <UntouchableView>
                <CallsToActions
                  setUserDeactivationReasons={setUserDeactivationReasons}
                  userDeactivationReasons={userDeactivationReasons}
                  disabledBanButton={disabledBanButton}
                  setDisabledBanButton={setDisabledBanButton}
                  isBanUser={isBanUser}
                  setIsBanUser={setIsBanUser}
                  getUserDeactivationReasons={getUserDeactivationReasons}
                  internalCommentBOV1={parseInternalCommentBoV1(user?.internal_comment)} />
              </UntouchableView>}
            onChange={onClickTab}
            defaultActiveKey={activeTab}
          />
        </div>
      </div>
    </StatusErrorWrapper>
  );
}

export default User;
