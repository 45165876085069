import { DeliveryListTabNames, useCustomHtml, useScreenSize } from '@shopopop/react-hooks';
import { Badge, Card, Flex, Pagination, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeliveriesListCardProps } from '../../interfaces/DeliveriesListCardProps';
import styled from '@emotion/styled';
import DeliveriesList from '../Deliveries/DeliveriesList';
import { useNavigate } from 'react-router-dom';
import DeliveryCardListItem from './DeliveryCardListItem';
import { DeliveryListItemProps } from '../../interfaces/DeliveryListProps';
import { DEFAULT_PAGINATION } from '@shopopop/backoffice-frontend-utils';
import EmptyNoDelivery from '../Empty/EmptyNoDelivery';

function DeliveriesListCard({
  scheduledCount,
  inDeliveryCount,
  deliveredCount,
  interruptedCount,
  totalCount,
  deliveries,
  loading,
}: Readonly<DeliveriesListCardProps>) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { token: { colorPrimary, colorPrimaryBg, colorError, colorErrorBg, colorTextSecondary }} = theme.useToken();
  const { mobileMode } = useScreenSize();
  const searchParams = new URLSearchParams(location.search);

  const [activeTabKey, setActiveTabKey] = useState(searchParams.get('tab') as DeliveryListTabNames || DeliveryListTabNames.Schedule);
  const [pageSize, setPageSize] = useState(Number(searchParams.get('per_page')) || DEFAULT_PAGINATION.pageSize);
  const [currentPage, setCurrentPage] = useState(Number(searchParams.get('page')) || DEFAULT_PAGINATION.current);
  const [totalDeliveries, setTotalDeliveries] = useState(0);

  useEffect(() => {
    setCurrentPage(Number(searchParams.get('page')) || DEFAULT_PAGINATION.current);
    setPageSize(Number(searchParams.get('per_page')) || DEFAULT_PAGINATION.pageSize);
    setTotalDeliveries(Number(searchParams.get('tabCount')) || 0);
    setActiveTabKey(searchParams.get('tab') as DeliveryListTabNames || DeliveryListTabNames.Schedule);
  }, [searchParams]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    setCurrentPage(page);
    setPageSize(pageSize);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('per_page', pageSize.toString());
    searchParams.set('page', page.toString());
    navigate(`?${searchParams.toString()}`);
  };


  const extraTabMessage = useCustomHtml({
    inputString: t('DISPLAY_DELIVERY_DELIVERED_NBR', {
      deliveredCount,
      totalCount,
    }),
    keyValues: [
      {
        key: '[NBR_DELIVERY_DELIVERED]',
        value: deliveredCount.toString(),
      },
      {
        key: '[TOTAL_DELIVERIES]',
        value: totalCount.toString(),
        styles: `color:${colorTextSecondary}`,
      },
    ],
  });

  const handleTabChange = (key: string) => {
    setActiveTabKey(key as DeliveryListTabNames);
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('tab', key);
    searchParams.set('page', DEFAULT_PAGINATION.current.toString());
    searchParams.set('per_page', DEFAULT_PAGINATION.pageSize.toString());
    navigate(`?${searchParams.toString()}`);
  };

  const tabList = [
    { key: DeliveryListTabNames.Schedule, count: scheduledCount, label: t('DELIVERY_LIST_TAB1') },
    { key: DeliveryListTabNames.InProgress, count: inDeliveryCount, label: t('DELIVERY_LIST_TAB2') },
    { key: DeliveryListTabNames.DeliveredAndFinished, count: deliveredCount, label: t('DELIVERY_LIST_TAB3') },
    { key: DeliveryListTabNames.Interrupted, count: interruptedCount, label: t('DELIVERY_LIST_TAB4'), isError: true },
  ].map(({ key, count, label, isError }) => ({
    key,
    label: (
      <Flex gap={10} align='center'>
        {label}
        <Badge
          count={count}
          style={{
            backgroundColor: activeTabKey === key ? colorPrimary : (isError ? colorErrorBg : colorPrimaryBg),
            color: activeTabKey === key ? colorPrimaryBg : (isError ? colorError : colorPrimary),
          }}
          showZero={true}
        />
      </Flex>
    ),
  }));


  function generateContentForMobile(deliveries: DeliveryListItemProps[]): React.ReactNode {
    if (loading) {
      return <Card loading={true} />;
    }

    if (deliveries?.length === 0) {
      return <EmptyNoDelivery />;
    }

    return (
      <Flex vertical gap='small'>
        {deliveries.map((delivery) => (
          <DeliveryCardListItem
            deliveryId={delivery.delivery_id}
            recipient={{
              firstName: delivery.recipient.first_name,
              lastName: delivery.recipient.last_name,
            }}
            pickupTime={{ start: delivery.withdrawal_start_utc, end: delivery.withdrawal_end_utc }}
            deliveryTime={{ start: delivery.delivery_start_utc, end: delivery.delivery_end_utc }}
            status={delivery.status}
            tabName={delivery.tab}
            shiftedDate={delivery.shifted_date}
            shiftedTime={delivery.shifted_time}
            incident={delivery.incident}
            key={delivery.delivery_id}
            isArchived={delivery.is_archived}
            drive={{
              name: delivery.drive.drive_name,
              zipCode: delivery.drive.drive_zip_code,
              city: delivery.drive.drive_city,
            }}
          />
        ))}
      </Flex>
    );
  }

  function generateContentForTab(deliveries: DeliveryListItemProps[]): React.ReactNode {
    const contentForMobile = (
      <Flex vertical gap='large'>
        {generateContentForMobile(deliveries)}
        {deliveries.length > 0 && (
          <Flex justify='end'>
            <Pagination
              current={currentPage}
              total={totalDeliveries}
              pageSize={pageSize}
              onChange={handlePaginationChange}
              showSizeChanger={true}
              pageSizeOptions={DEFAULT_PAGINATION.pageSizeOptions}
            />
          </Flex>
        )}
      </Flex>
    );

    return mobileMode ? contentForMobile : <DeliveriesList currentTab={activeTabKey} deliveries={deliveries} loading={loading} />;
  }

  const contentList: Record<string, React.ReactNode> = {
    schedule: generateContentForTab(deliveries),
    inProgress: generateContentForTab(deliveries),
    deliveredAndFinished: generateContentForTab(deliveries),
    interrupted: generateContentForTab(deliveries),
  };

  return (
    <StyledOnlyTabsCard>
      <Card
        style={{ width: '100%' }}
        tabList={tabList}
        activeTabKey={activeTabKey}
        tabBarExtraContent={!mobileMode && extraTabMessage}
        onTabChange={handleTabChange}
        tabProps={{ tabBarGutter: 30, size: 'small' }}
      >
        {contentList[activeTabKey]}
      </Card>
    </StyledOnlyTabsCard>
  );
}

const StyledOnlyTabsCard = styled.div`
  .ant-card {
    .ant-tabs-nav-operations {
      display: none;
    }
  }
`;


export default DeliveriesListCard;
