import { ReactNode, useEffect } from 'react';
import {
  Card,
  Form,
  DatePicker,
  TimePicker,
  Typography,
  Radio,
  Alert,
  Flex,
  FormInstance,
  theme,
  Space,
  Input,
} from 'antd';
import { ArrowRightOutlined, CalendarOutlined, ClockCircleTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDeliveryDateTime, useScreenSize } from '@shopopop/react-hooks';
import styled from '@emotion/styled';
import { getFormattedDateOrOtherDate } from '@shopopop/backoffice-frontend-utils';

const { Text } = Typography;

function DeliveryDateTime({ form }: { form: FormInstance }): ReactNode {
  const { t } = useTranslation();
  const { token: { screenMD, screenLG, colorPrimary }} = theme.useToken();
  const { width, mobileMode } = useScreenSize();

  const {
    startDate,
    endDate,
    validateTimeRange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleDateChange,
  } = useDeliveryDateTime(form);

  const handleDatePickerChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      const value = date.startOf('day').format();
      form.setFieldsValue({ _deliveryDate: value });
      handleDateChange(value);
    } else {
      form.setFieldsValue({ _deliveryDate: null });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      _deliveryDate: dayjs(startDate).startOf('day').format(),
      startDate: startDate,
      endDate: endDate,
    });
  }, []);

  const renderDatePicker = () => (
    <DatePicker
      value={dayjs(form.getFieldValue('_deliveryDate'))}
      onChange={(value) => handleDatePickerChange(value)}
      format={'dddd D MMMM'}
      disabledDate={(current) => current?.isBefore(dayjs().startOf('day'))}
      allowClear={false}
      style={mobileMode ? { width: '100%' } : {}}
      inputReadOnly
      suffixIcon={<CalendarOutlined style={{ color: colorPrimary }}/>}
    />
  );

  const disableTimePickerArray = (start: number, end: number): number[] => {
    const result: number[] = [];
    for (let i = start; i <= end; i++) {
      result.push(i);
    }
    return result;
  };

  return (
    <Card title={t('DATE_TIMESLOT_TITLE')}>

      <Form.Item name="startDate" style={{ display: 'none', visibility: 'hidden' }}>
        <Input />
      </Form.Item>
      <Form.Item name="endDate" style={{ display: 'none', visibility: 'hidden' }}>
        <Input />
      </Form.Item>

      <Flex vertical gap={24}>
        <Space direction={'vertical'}>
          <Form.Item
            label={t('DELIVERY_DATE')}
            name="_deliveryDate"
            rules={[{ required: true, message: t('DELIVERY_DATE_REQUIRED_ERROR') }]}
            style={{ marginBottom: 0 }}
          >
            <Radio.Group
              onChange={(e) => handleDateChange(e.target.value)}
              optionType={'button'}
              style={{ width: width > screenLG ? 'auto' : '100%' }}
            >
              <StyledRadioButton
                value={dayjs().startOf('day').format()}
                colorPrimary={colorPrimary}
              >
                <Flex vertical>
                  <Text strong>{t('TODAY')}</Text>
                  <Text className="hide-mobile">{(dayjs().format('dddd D MMMM')).charAt(0).toUpperCase() + dayjs().format('dddd D MMMM').slice(1)}</Text>                </Flex>
              </StyledRadioButton>
              <StyledRadioButton
                value={dayjs().add(1, 'day').startOf('day').format()}
                colorPrimary={colorPrimary}
              >
                <Flex vertical>
                  <Text strong>{t('TOMORROW')}</Text>
                  <Text className="hide-mobile">{(dayjs().add(1, 'day').format('dddd D MMMM')).charAt(0).toUpperCase() + (dayjs().add(1, 'day').format('dddd D MMMM')).slice(1)}</Text>
                </Flex>
              </StyledRadioButton>
              <StyledRadioButton
                value={dayjs().add(2, 'day').startOf('day').format()}
                colorPrimary={colorPrimary}
              >
                <Flex vertical>
                  <Text strong>{t('AFTER_TOMORROW')}</Text>
                  <Text className="hide-mobile">{(dayjs().add(2, 'day').format('dddd D MMMM')).charAt(0).toUpperCase() + (dayjs().add(2, 'day').format('dddd D MMMM')).slice(1)}</Text>
                </Flex>
              </StyledRadioButton>
              {width > screenMD && (
                <StyledRadioButton
                  value={getFormattedDateOrOtherDate(form.getFieldValue('_deliveryDate'))}
                  colorPrimary={colorPrimary}
                >
                  <Flex vertical align={'center'}>
                    <Text className="hide-mobile"><CalendarOutlined /></Text>
                    {startDate.isAfter(dayjs().add(2, 'day').endOf('day')) && (
                      <Text>{startDate.format(width > screenLG ? 'dddd D MMMM' : 'DD/MM/YY')}</Text>
                    )}
                    {
                      startDate.isBefore(dayjs().add(2, 'day').endOf('day')) && (
                        <Text>{t('OTHER_DATE')}</Text>
                      )
                    }
                  </Flex>
                  <StyledDatePickerRadioButton>
                    {renderDatePicker()}
                  </StyledDatePickerRadioButton>
                </StyledRadioButton>
              )}
            </Radio.Group>
          </Form.Item>
          {mobileMode && renderDatePicker()}
        </Space>

        <Form.Item
          label={t('DELIVERY_TIME_SLOT')}
          name="_timeRange"
          rules={[{ required: true, validator: validateTimeRange }]}
          validateTrigger={['onBlur', 'onChange']}
          style={{ width: width > screenMD ? 'auto' : '100%' }}
        >
          <Flex align={'center'} gap={width > screenMD ? 24 : 12} vertical={width <= screenMD}>
            <TimePicker
              format="HH:mm"
              value={startDate}
              minuteStep={15}
              disabledTime={() => ({
                disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 21, 22, 23],
                disabledMinutes: (selectedHour) => (selectedHour === 20 ? disableTimePickerArray(1, 59) : []),
              })}
              hideDisabledOptions
              needConfirm={false}
              use12Hours={false}
              onChange={handleStartTimeChange}
              allowClear={false}
              style={{
                width: width > screenMD ? 'auto' : '100%',
                minWidth: 205,
              }}
              suffixIcon={<ClockCircleTwoTone twoToneColor={colorPrimary}/>}
            />
            {width > screenMD && <ArrowRightOutlined style={{ color: colorPrimary }}/>}
            <TimePicker
              format="HH:mm"
              value={endDate}
              minuteStep={15}
              disabledTime={() => ({
                disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 22, 23],
                disabledMinutes: (selectedHour) => (selectedHour === 21 ? disableTimePickerArray(1, 59) : []),
              })}
              hideDisabledOptions
              needConfirm={false}
              use12Hours={false}
              onChange={handleEndTimeChange}
              allowClear={false}
              style={{
                width: width > screenMD ? 'auto' : '100%',
                minWidth: 205,
              }}
              suffixIcon={<ClockCircleTwoTone twoToneColor={colorPrimary}/>}
            />
          </Flex>
        </Form.Item>
      </Flex>

      <Alert message={t('DELIVERY_TIMESLOT_INSTRUCTION')} type="info" showIcon />
    </Card>
  );
}

const StyledRadioButton = styled(Radio.Button)<{ colorPrimary: string }>`
    height: auto;
    padding: 5px 16px;
    text-align: center;
    outline: 0 !important;

    @media (max-width: 992px) {
        width: calc(100% / 4);

        .ant-typography {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .hide-mobile {
            display: none;
        }
    }

    @media (max-width: 576px) {
        width: calc(100% / 3);
        .ant-picker {
            height: auto !important;
        }
    }

    &.ant-radio-button-wrapper-checked .ant-typography {
      color: ${({ colorPrimary }) => colorPrimary};
    }

    .anticon {
        color: ${({ colorPrimary }) => colorPrimary};
    }
`;

const StyledDatePickerRadioButton = styled.div`
    margin-bottom: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer !important;

    .anticon {
        display: none;
    }

    .ant-picker {
        width: 100%;
        height: 56px;
        border-radius: 0;
        border: 0;
        padding: 0;

        .ant-picker-input {
            height: 100%;
            border-radius: 0;

            input {
                height: 100%;
                border-radius: 0;
                cursor: pointer;
            }

            .ant-picker-suffix {
                display: none;
            }
        }
    }
`;

export default DeliveryDateTime;
